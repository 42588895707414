<template>
  <TagsTable/>
</template>
<script>
import TagsTable from '@/generated/ziqni/views/tags/TagsTable';

export default {
  components: {
    TagsTable
  },
  props: {}
};
</script>