<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, name, key, description, entityTypes, metadata]
    x-vue-custom-fields: [ ]
    x-vue-tags: [ ]
    x-vue-metadata: [ metadata ]
    -->
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="tags"
  />
</template>
<script>
  import { tags } from '@/config/descriptions/tags';
  import { tagsTexts } from '@/config/pageTexts/tags.json';

  export default {
    name: 'TagsTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'tags/tags',
            fields: 'tags/fields',
            sortableFields: 'tags/sortableFields',
            pages: 'tags/pages',
            loading: 'tags/loading',
            resultCount: 'tags/resultCount',
            totalRecords: 'tags/totalRecords',
            originalFields: 'tags/originalFields',
            searchableAllFields: 'tags/searchableAllFields',
            success: 'tags/success',
            message: 'tags/message',
          },
          actions: {
            fields: 'tags/handleFields',
            delete: 'tags/handleDeleteTags',
            reset: 'tags/handleClearFilter',
            query: 'tags/handleGetTagsByQuery',
          },
        },
        settings: {
          describe: { ...tags },
          page: { ...tagsTexts },
        },
        route: {
          create: 'CreateTag',
          edit: 'EditTag',
          preview: 'PreviewTag',
        },
      };
    },
  };
</script>